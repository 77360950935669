import przemek from '@images/workers/przemek.webp';
import { phoneNumberPrzemek } from '@utils/variables';

const nbsp = '\u00A0';

export const pageData = {
  id: 1,
  title: `Doradztwo biznesowe`,
  // subtitle: `Prowadzimy szkolenia i procesy HR dla naszych Klientów:`,
  slug: `doradztwo-biznesowe`,
  worker: {
    employee: `Przemysław Pruszyński`,
    employeePhone: `${phoneNumberPrzemek}`,
    employeePhoto: przemek,
  },
  description: {
    content: `Dzięki wieloletniemu doświadczeniu i współpracy z Klientami biznesowymi jesteśmy w stanie zaoferować profesjonalne wsparcie w zakresie prowadzenia i rozwoju biznesu naszych Klientów. Oferujemy usługi abonamentowe w postaci dyrektora finansowego oraz zarządzającego do${nbsp}wynajęcia. Zajmujemy się również procesami związanymi z fuzjami, przejęciami oraz sukcesją przedsiębiorstw, co oznacza pełne wsparcie merytoryczne, formalne i organizacyjne w tym zakresie. 
Zapraszamy do${nbsp}kontaktu i skorzystania z naszych usług.
`,
    list: [],
  },
};

export default {};
