import { pageData } from '@utils/subpages-data/doradztwo-biznesowe';
import React from 'react';

import Subpage from './_subpage';

const DevelopmentProjects = () => {
  return <Subpage data={pageData} />;
};

export default DevelopmentProjects;
